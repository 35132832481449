<template>
  <main v-if="news">
    <h1 class="news-title">{{ news.newTitle }}</h1>
    <div class="news-info">
      <span class="author">Author：{{ news.author }}</span>
      <span class="date">{{ dateFormat(news.createTime) }}</span>
      <span class="count"><img style="height: 15px" class="view-img" src="@/assets/img/common/icon27.png" alt="浏览数："/>
             {{ news.clickCount }}</span>
    </div>
    <div class="news-content">
      <div v-html="news.newContent"></div>
    </div>
    <div class="adjacent-news">
      <h3 class="prev" v-if="previous">
        <router-link :to="{ name: 'NewsDetail', query: { newsid: previous.id } }">
          <span>Previous：</span>
          {{ previous.newTitle }}
        </router-link>
      </h3>
      <h3 class="next" v-if="next">
        <router-link :to="{ name: 'NewsDetail', query: { newsid: next.id } }">
          <span>Next：</span>
          {{ next.newTitle }}
        </router-link>
      </h3>
    </div>
    <recommend title="Popular Article" :list="recommendList" v-if="recommendList && recommendList.length>0"></recommend>
  </main>
  <loading :options="loadOption" v-else></loading>

  <preview
    v-if="imgList.length && previewing"
    @close="closePreview"
    :imgList="imgList"
    :currentIndex="currentIndex"
  ></preview>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  watch,
  nextTick,
  inject,
  onUpdated
} from "vue";

//  components
import Loading from "@/components/UI/Loading";
import Recommend, { RecommnedList } from "@/components/common/Recommend.vue";
import Preview from "@/components/UI/Preview";
import { ImgItem } from "@/components/UI/Preview/src/Preview.vue";
import { useRoute, useRouter } from "vue-router";
import $, { param } from "jquery";
import axios from "@/api/axios";
import dateFormat from "@/utils/dateFormat";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import Modal from "@/components/UI/Modal";
import wx from "weixin-sdk-js";

export default defineComponent({
  name: "about",
  components: {
    Recommend,
    Loading,
    Preview,
  },

  setup() {
    const shareTitle = ref('')
    //  get page params
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.query.newsid);
    const news = ref(null);
    //  recommend
    const recommendList = reactive<RecommnedList[]>([]);
    //  comment
    const totalCount = ref(0);
    const loading = ref(true);
    const previous = ref(null);
    const next = ref(null);

    function getRenderData(id: string) {
      //  async data
      news.value = null;
      loading.value = true;
      recommendList.length = 0;
      axios
        .get("/M/News/NewsDetail/" + id)
        .then(res => {
          if (res.data.success) {
            const data = res.data.obj;
            news.value = data;
            shareTitle.value = data.newTitle
            document.title = data.newTitle + " | Weiye";
            loading.value = false;
            route.meta.title = data.newTitle;
            route.meta.content.keywords = data.keyword;
            route.meta.content.description = data.newSummary;
            asyncLoadJs(
              "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
              "about"
            ).then(() => {
              asyncLoadJs(
                "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                "about"
              ).then(() => {
                nextTick(() => {
                  data.newContent.match(/<script.*?>([\s\S]+?)<\/script>/gim);
                  eval(RegExp.$1);
                });
              });
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
          loading.value = false;
        });
      axios
        .get("/M/News/NewsDetailRelation/" + id)
        .then(res => {
          if (res.data.success) {
            const data = res.data.obj;
            data.listRecommend.forEach((item: any) => {
              const link = {
                name: "NewsDetail",
                query: { newsid: item.id }
              };
              recommendList.push({
                id: item.id,
                title: item.newTitle,
                link
              });
            });
            totalCount.value = data.totalCount;
            previous.value = data.previous;
            next.value = data.next;
          } else {
            Toast({
              type: "error",
              title: res.data.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

    getRenderData(id.value as string);
    const loadOption = {
      text: "Loading...",
      color: "#df0024",
      textColor: "#df0024"
    };
    //  preview picture
    const imgList = reactive<ImgItem[]>([]);
    const currentIndex = ref(0);
    const previewing = ref(false);
    function getSrcIndex(src: string, array: JQuery<HTMLElement>): number {
      let index = 0;
      array.each(function(i, item) {
        if ($(this).prop("src") == src) {
          index = i;
        }
      });
      return index;
    }

    onMounted(() => {
      $(document).on("click", ".news-content img", function(e: Event) {
        const src = $(this).prop("src");
        currentIndex.value = getSrcIndex(src, $(".news-content img"));
        previewing.value = true;
      });
    });
    function closePreview() {
      previewing.value = false;
    }

    watch(
      () => {
        return news.value;
      },
      () => {
        nextTick(() => {
          const imgDom = document.querySelectorAll(".news-content img");
          imgDom.forEach(img => {
            imgList.push({ src: img.getAttribute("src") as string });
          });
        });
      }
    );

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        }
      ],
      (to, from) => {
        console.log(to, from);
        // 避免登录时触发页面刷新
        if (to[0].newsid && !from[1].valid) {
          getRenderData(to[0].newsid as string);
        }
      }
    );

    return {
      news,
      recommendList,
      totalCount,
      dateFormat,
      loadOption,
      imgList,
      previewing,
      currentIndex,
      loading,
      closePreview,
      previous,
      next,
      id,
    };
  }
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 70px;
  text-align: center;
}

.new-apply-gp {
  // margin: 0 auto;
  // height: 50px;
  // line-height: 50px;
  // width: 100px;
  // text-align: center;
  // margin-bottom: 10px;
  // background-color: #df0024;
  // color: #fff;
  // font-size: 16px;

  text-align: center;
  width: 3.733rem;
  height: 1.067rem;
  line-height: 1.067rem;

  font-size: 0.427rem;
  border: 0.027rem solid #df0024;
  background-color: #df0024;
  border-radius: 0.133rem;
  margin: 15px auto;
  box-sizing: border-box;
  color: #fff;
}

.news-title {
  color: #444444;
  font-size: 16px;
  font-weight: bold;
  width: 355px;
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 20px;
}

.news-info {
  color: #999999;
  font-size: 14px;
  line-height: 20px;
}

.author,
.date {
  margin-right: 20px;
}

.count {
  margin-right: 0;
}

.news-content {
  width: 355px;

  margin: 0 auto;
  margin-top: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: justify;
  font-size: 16px;
  color: #666666;
  line-height: 25px;
  overflow: hidden;

  border-bottom: 1px solid #eee;
  :deep(p) {
    margin: 1em 0;
    word-break: break-word;
  }
  :deep(a) {
    color: #3498db;
    text-decoration: underline!important;
  }
  :deep(video) {
    width: 100% !important;
  }
  :deep(table) {
    width: 100% !important;
  }
  :deep(img) {
    width: 100% !important;
    height: auto !important;
  }
  :deep(table) {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
  }
  :deep(.articleVideo) {
    width: 8.933rem;
    height: 5.013rem;
    margin: 0 auto;
  }
}

.author a {
  color: rgb(61, 194, 214);
  font-size: 14px;
}
.adjacent-news {
  margin: 10px auto;
  text-align: left;
  width: 355px;
}
.adjacent-news h3 {
  margin: 0;
  font-weight: normal;
  margin: 0 10px;
}

.adjacent-news a {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  color: #666;
}

.adjacent-news a span {
  color: #05b3d2;
  font-size: 14px;
}
</style>
